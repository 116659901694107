import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const ProjectsPage = () => {
  return (
    <Layout pageTitle="Projects">
      <p>Something organized　まとまりをもった何か</p>
      <h2>Dict Driven HASP</h2>
      <p>PythonでNewHASPを操作するライブラリ作成の記録。NewHASPの設定ファイルは固定長データであり、テキストファイルを直接編集して作成するのは望ましくない。やりづらいしバグを起こしやすい。そこでPythonでNewHASPを実行できるようにすることにした。Pythonは3.7以上を想定している。</p>
      <ul>
        <li>
          NewHASPをインストール  
        </li>
        <li>
          <Link to="/projects/ddhasp01">
          PythonからNewHASPを実行🐍
          </Link>
        </li>
        <li>
          <Link to="/projects/ddhasp02">
          NewHaspRunnerの中身🐍
          </Link>
        </li>
        <li>
          <Link to="/projects/ddhasp03">
          設定ファイルの読み書き🐍
          </Link>
        </li>      
        <li>
          設定ファイルをJSON化🐍
        </li>
        <li>
          入出力データの集計補助🐍
        </li>
      </ul>
    </Layout>
  )
}
export default ProjectsPage
